<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Article" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Description"
                  type="textarea"
                  v-model="description"
                  @input="delete errors.description"
                  :error="!!errors.description"
                  :error-messages="errors.description"
                />
                <va-input
                  label="Alias"
                  v-model="alias"
                  @input="delete errors.alias"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                />
                <va-card class="mb-3" title="Image">
                  <div v-if="imageExisting">
                    <a style="display: block;" target="_blank" :href="imageExisting"><img style="max-width: 400px;" :src="imageExisting"></a>
                    <div><va-button small color="danger" @click="imageExisting = deleteImage(imageId) ? '' : imageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    @input="delete errors.image"
                    dropzone
                    v-model="image"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', 'ArticleImage', 'Article[image]', image[0])">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Image Mobile">
                  <div v-if="imageMobileExisting">
                    <a style="display: block;" target="_blank" :href="imageMobileExisting"><img style="max-width: 400px;" :src="imageMobileExisting"></a>
                    <div><va-button small color="danger" @click="imageMobileExisting = deleteImage(imageMobileId) ? '' : imageMobileExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    @input="delete errors.imageMobile"
                    type="gallery"
                    dropzone
                    v-model="imageMobile"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('imageMobile', 'ArticleImageMobile', 'Article[imageMobile]', imageMobile[0])">Завантажити</va-button>
                  <div v-if="errors.imageMobile" style="color: red;">{{ errors.imageMobile[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Thumb Image">
                  <div v-if="imageThumbExisting">
                    <a style="display: block;" target="_blank" :href="imageThumbExisting"><img style="max-width: 400px;" :src="imageThumbExisting"></a>
                    <div><va-button small color="danger" @click="imageThumbExisting = deleteImage(imageThumbId) ? '' : imageThumbExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    @input="delete errors.imageThumb"
                    dropzone
                    v-model="imageThumb"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('imageThumb', 'ArticleImageThumb', 'Article[imageThumb]', imageThumb[0])">Завантажити</va-button>
                  <div v-if="errors.imageThumb" style="color: red;">{{ errors.imageThumb[0] }}</div>
                </va-card>
                <va-toggle label="Published" small v-model="published"/>
                <va-date-picker
                  label="Published at *"
                  :config="{locale: {firstDayOfWeek: 1}, dateFormat: 'Y-m-d'}"
                  v-model="published_at"
                  @input="delete errors.published_at"
                  :error="!!errors.published_at"
                  :error-messages="errors.published_at"
                  weekDays
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <div class="row">
                  <div class="flex xs6">
                    <va-select
                      textBy="label"
                      v-model="selectedConstructor"
                      no-clear
                      :options="['ConstructorQuoteBlock', 'ConstructorTextAndImageBlock',  'ConstructorVideoBlock', 'ConstructorTextBlock']">
                    </va-select>
                  </div>
                  <div class="flex xs6">
                    <va-button style="margin-bottom: 10px;" small @click="addInput">Додати</va-button>
                  </div>
                </div>
                <draggable v-model="constructors" handle=".handle">
                  <div
                    class="mb-4"
                    v-for="(constructor, index) in constructors"
                    :key="index"
                  >
                    <va-card stripe="info" :title="constructor.config.componentName">
                      <div class="text-right">
                        <i class="fa fa-align-justify handle"></i>
                      </div>
                      <div v-if="constructor.config.componentName === 'ConstructorQuoteBlock'">
                        <va-input
                          type="textarea"
                          label="Content"
                          v-model="constructor.config.options.content"
                          @input="delete errors[`constructorquoteblock-${index}-content`]"
                        />
                        <div v-if="errors[`constructorquoteblock-${index}-content`]" style="color: red;">{{ errors[`constructorquoteblock-${index}-content`][0][0] }}</div>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="constructor.config.componentName === 'ConstructorTextAndImageBlock'">
                        <div style="margin-bottom: 15px;">
                          <span>Content *</span>
                          <ckeditor
                            v-model="constructor.config.options.content"
                            :editor="editor"
                            :config="editorConfig"
                            @input="delete errors[`constructortextandimageblock-${index}-content`]"
                          ></ckeditor>
                          <div v-if="errors[`constructortextandimageblock-${index}-content`]" style="color: red;">{{ errors[`constructortextandimageblock-${index}-content`][0][0] }}</div>
                        </div>
                        <va-card class="mb-3" title="Image">
                          <a v-if="constructor.config.options.imageExisting" style="display: block;" target="_blank" :href="adminUrl + constructor.config.options.imageExisting"><img style="max-width: 400px;" :src="adminUrl + constructor.config.options.imageExisting"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            v-model="constructor.config.options.image"
                            style="margin-bottom: 25px;"
                            @input="delete errors[`constructortextandimageblock-${index}-image`]"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'text-and-image-image', `ConstructorTextAndImageBlock[${index}][image]`, constructor.config.options.image[0], 'backend\\modules\\constructor\\models\\ConstructorTextAndImageBlock', constructor.id, constructor.sign)">Завантажити</va-button>
                          <div v-if="errors[`constructortextandimageblock-${index}-image`]" style="color: red;">{{ errors[`constructortextandimageblock-${index}-image`][0][0] }}</div>
                        </va-card>
                        <va-toggle small label="Image At Left" v-model="constructor.config.options.imageAtLeft"></va-toggle>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="constructor.config.componentName === 'ConstructorTextBlock'">
                        <div style="margin-bottom: 15px;">
                          <span>Content *</span>
                          <ckeditor
                            v-model="constructor.config.options.content"
                            :editor="editor"
                            :config="editorConfig"
                            @input="delete errors[`constructortextblock-${index}-content`]"
                          ></ckeditor>
                        </div>
                        <div v-if="errors[`constructortextblock-${index}-content`]" style="color: red;">{{ errors[`constructortextblock-${index}-content`][0][0] }}</div>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="constructor.config.componentName === 'ConstructorVideoBlock'">
                        <va-card class="mb-3" title="Image">
                          <a v-if="constructor.config.options.imageExisting" style="display: block;" target="_blank" :href="adminUrl + constructor.config.options.imageExisting"><img style="max-width: 400px;" :src="adminUrl +constructor.config.options.imageExisting"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            v-model="constructor.config.options.image"
                            @input="delete errors[`constructorvideoblock-${index}-image`]"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'image', `ConstructorVideoBlock[${index}][image]`, constructor.config.options.image[0], 'backend\\modules\\constructor\\models\\ConstructorVideoBlock', constructor.id, constructor.sign)">Завантажити</va-button>
                          <div v-if="errors[`constructorvideoblock-${index}-image`]" style="color: red;">{{ errors[`constructorvideoblock-${index}-image`][0][0] }}</div>
                        </va-card>
                        <va-card class="mb-3" title="Video">
                          <a v-if="constructor.config.options.videoExisting" style="display: block;" target="_blank" :href="adminUrl + constructor.config.options.videoExisting">{{ adminUrl + constructor.config.options.videoExisting }}</a>
                          <span>Завантажити нове відео</span>
                          <va-file-upload
                            dropzone
                            v-model="constructor.config.options.video"
                            style="margin-bottom: 25px;"
                            @input="delete errors[`constructorvideoblock-${index}-video`]"
                          />
                          <va-button @click="uploadImage(`[${index}]video`, 'video', `ConstructorVideoBlock[${index}][video]`, constructor.config.options.video[0], 'backend\\modules\\constructor\\models\\ConstructorVideoBlock', constructor.id, constructor.sign)">Завантажити</va-button>
                          <div v-if="errors[`constructorvideoblock-${index}-video`]" style="color: red;">{{ errors[`constructorvideoblock-${index}-video`][0][0] }}</div>
                        </va-card>
                        <va-input
                          label="Youtube link"
                          v-model="constructor.config.options.link"
                          @input="delete errors[`constructorvideoblock-${index}-link`]"
                        />
                        <div v-if="errors[`constructorvideoblock-${index}-link`]" style="color: red;">{{ errors[`constructorvideoblock-${index}-link`][0][0] }}</div>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                    </va-card>
                  </div>
                </draggable>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                  @input="delete errors.metaTitle"
                  :error="!!errors.metaTitle"
                  :error-messages="errors.metaTitle"
                />
                <va-input
                  label="Meta Description"
                  v-model="metaDescription"
                  @input="delete errors.metaDescription"
                  :error="!!errors.metaDescription"
                  :error-messages="errors.metaDescription"
                />
                <va-input
                  label="OG Title"
                  v-model="OgTitle"
                  @input="delete errors.OgTitle"
                  :error="!!errors.OgTitle"
                  :error-messages="errors.OgTitle"
                />
                <va-input
                  label="OG Description"
                  v-model="OgDescription"
                  @input="delete errors.OgDescription"
                  :error="!!errors.OgDescription"
                  :error-messages="errors.OgDescription"
                />
                <va-card class="mb-3" title="OG Image">
                  <div v-if="OgImageExisting">
                    <a style="display: block;" target="_blank" :href="OgImageExisting"><img style="max-width: 400px;" :src="OgImageExisting"></a>
                    <div><va-button small color="danger" @click="OgImageExisting = deleteImage(OgImageId) ? '' : OgImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="OgImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', null, `SeoEntityArticle[image]`, OgImage[0], 'common\\models\\SeoEntityArticle', seoId)">Завантажити</va-button>
                  <div v-if="errors.OgImage" style="color: red;">{{ errors.OgImage[0] }}</div>
                </va-card>
                <va-input
                  label="seo text title"
                  v-model="seoTextTitle"
                  @input="delete errors.seoTextTitle"
                  :error="!!errors.seoTextTitle"
                  :error-messages="errors.seoTextTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>seo text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="seo text see more text"
                  v-model="seoTextSeeMore"
                  @input="delete errors.seoTextSeeMore"
                  :error="!!errors.seoTextSeeMore"
                  :error-messages="errors.seoTextSeeMore"
                />
                <va-input
                  label="Seo Text Hide Text"
                  v-model="seoTextHide"
                  @input="delete errors.seoTextHide"
                  :error="!!errors.seoTextHide"
                  :error-messages="errors.seoTextHide"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import CKEditor from '@ckeditor/ckeditor5-vue'
import draggable from 'vuedraggable'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
    draggable: draggable,
  },
  data () {
    return {
      label: '',
      description: '',
      alias: '',
      image: [],
      imageId: '',
      imageExisting: '',
      imageMobile: [],
      imageMobileId: '',
      imageMobileExisting: '',
      imageThumb: [],
      imageThumbId: '',
      imageThumbExisting: '',
      published: false,
      published_at: '2020-10-30',

      selectedConstructor: 'ConstructorQuoteBlock',
      constructors: [],

      sign: '',

      errors: [],

      seoId: null,
      metaTitle: '',
      metaDescription: '',
      OgTitle: '',
      OgDescription: '',
      OgImage: [],
      OgImageId: '',
      OgImageExisting: '',
      seoTextTitle: '',
      seoText: '',
      seoTextSeeMore: '',
      seoTextHide: '',

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_1LK_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['Main', 'Constructor', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    this.sign = this.generateSign(32)
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' + today.getDate() : today.getDate())
    }
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    adminUrl () {
      return process.env.VUE_APP_1LK_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.label || !!this.errors.description || !!this.errors.alias || !!this.errors.image ||
          !!this.errors.imageMobile || !!this.errors.imageThumb || !!this.errors.published_at
      } else if (tab === 1) {
        const errors = Object.keys(this.errors)
        return errors.some(i => {
          return i.includes('constructorquoteblock') ||
            i.includes('constructortextandimageblock') ||
            i.includes('constructortextblock') ||
            i.includes('constructorvideoblock')
        })
      } else if (tab === 2) {
        return false
      }
      return false
    },
    getBlock (name) {
      const filtered = this.constructors.filter(i => i.config.componentName === name)
      if (filtered.length) {
        return filtered.map(i => {
          const newEl = {}
          if (i.id) {
            newEl.id = i.id
            newEl.position = this.constructors.findIndex(j => j.id === i.id)
          } else {
            newEl.sign = i.sign
            newEl.position = this.constructors.findIndex(j => j.sign === i.sign)
          }

          if (name === 'ConstructorTextAndImageBlock') {
            newEl.image_at_left = i.config.options.imageAtLeft ? 1 : 0
          }

          if (name === 'ConstructorVideoBlock') {
            newEl.link = i.config.options.link
          } else {
            newEl.content = i.config.options.content
          }

          return newEl
        })
      }
      return []
    },
    addInput () {
      switch (this.selectedConstructor) {
        case 'ConstructorQuoteBlock':
          this.constructors.push({
            sign: this.generateSign(32),
            config: {
              componentName: 'ConstructorQuoteBlock',
              options: {
                content: '',
              },
            },
          })
          break
        case 'ConstructorTextAndImageBlock':
          this.constructors.push({
            sign: this.generateSign(32),
            config: {
              componentName: 'ConstructorTextAndImageBlock',
              options: {
                content: '',
                image: [],
                imageExisting: '',
                ImageAtLeft: false,
              },
            },
          })
          break
        case 'ConstructorVideoBlock':
          this.constructors.push({
            sign: this.generateSign(32),
            config: {
              componentName: 'ConstructorVideoBlock',
              options: {
                content: '',
                image: [],
                imageExisting: '',
                video: [],
                videoExisting: '',
                link: '',
              },
            },
          })
          break
        case 'ConstructorTextBlock':
          this.constructors.push({
            sign: this.generateSign(32),
            config: {
              componentName: 'ConstructorTextBlock',
              options: {
                content: '',
              },
            },
          })
          break
      }
    },
    removeInput (index) {
      this.constructors.splice(index, 1)
    },
    generateSign (length) {
      return Math.random().toString(20).substr(2, length)
    },
    uploadImage (attr, entityAttribute, param, file, model = 'backend\\modules\\article\\models\\Article', id = this.$attrs.id, sign = this.sign) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (entityAttribute === null) {
        formData.append('id', this.seoId ? this.seoId : '0')
      } else {
        formData.append('entity_attribute', entityAttribute)
        if (id) {
          formData.append('id', id)
        } else {
          formData.append('sign', sign)
        }
      }

      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_1LK_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        label: this.label,
        description: this.description,
        alias: this.alias,
        published: this.published ? 1 : 0,
        published_at: this.published_at,
        sign: this.$attrs.id ? null : this.sign,
        id: this.$attrs.id ? this.$attrs.id : null,
        constructorBlocks: 'justToTriggerSavefield',

        ConstructorQuoteBlock: this.getBlock('ConstructorQuoteBlock'),
        ConstructorTextAndImageBlock: this.getBlock('ConstructorTextAndImageBlock'),
        ConstructorTextBlock: this.getBlock('ConstructorTextBlock'),
        ConstructorVideoBlock: this.getBlock('ConstructorVideoBlock'),

        SeoEntityArticle: {
          title: this.metaTitle,
          description: this.metaDescription,
          og_title: this.OgTitle,
          og_description: this.OgDescription,
          seo_text_title: this.seoTextTitle,
          seo_text: this.seoText,
          seo_text_see_more_text: this.seoTextSeeMore,
          seo_text_hide_text: this.seoTextHide,
        },
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_1LK_API_URL}/admin/article/${this.$attrs.id}?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: '1lk-article' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/article?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: '1lk-article' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/article/${this.$attrs.id}?lang=${this.locale}`)
          .then(response => {
            console.log(response.data)
            this.label = response.data.label
            this.description = response.data.description
            this.alias = response.data.alias
            this.published = !!response.data.published
            this.published_at = response.data.published_at

            this.constructors = response.data.blocks

            this.imageExisting = response.data.image ? process.env.VUE_APP_1LK_ADMIN + response.data.image : ''
            this.imageId = response.data.imageId
            this.imageMobileExisting = response.data.imageMobile ? process.env.VUE_APP_1LK_ADMIN + response.data.imageMobile : ''
            this.imageMobileId = response.data.imageMobileId
            this.imageThumbExisting = response.data.imageThumb ? process.env.VUE_APP_1LK_ADMIN + response.data.imageThumb : ''
            this.imageThumbId = response.data.imageThumbId

            this.seoId = response.data.seo.id
            this.metaTitle = response.data.seo.title
            this.metaDescription = response.data.seo.description
            this.OgTitle = response.data.seo.og_title
            this.OgDescription = response.data.seo.og_description
            this.seoTextTitle = response.data.seo.seo_text_title
            this.seoText = response.data.seo.seo_text ? response.data.seo.seo_text : ''
            this.seoTextSeeMore = response.data.seo.seo_text_see_more_text
            this.seoTextHide = response.data.seo.seo_text_hide_text

            this.OgImageExisting = response.data.seo.og_image ? process.env.VUE_APP_1LK_ADMIN + response.data.seo.og_image : null
            this.OgImageId = response.data.seo.og_image_id
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.handle {
  font-size: 24px;
}
</style>
